import React from 'react'
import ConsultingTemplate from 'components/templates/ConsultingTemplate/ConsultingTemplate'

export default function ConsultingSafetyOnConstructionSitePage() {
  const seoKeywords = ['SEO0', 'SEO1', 'SEO2']

  const productName = 'Sicurezza Nel Cantiere'

  const description =
    'Assicuriamo la professionalità e la competenza per i compiti di COORDINATORE PER LA SICUREZZA in fase di progettazione e di esecuzione (ex D. Lgs. 494.96 applicazione D. Lgs. 81.08 e smi) oltre che di RESPONSABILE DEI LAVORI.<br> Con l’ausilio di software d’avangardia redigiamo e/o valutiamo per conto terzi:<br><br> • PIANI OPERATIVI DI SICUREZZA (POS)<br> • PIANI DI SICUREZZA E COORDINAMENTO (PSC)<br> • DOCUMENTAZIONE DI CANTIERE<br><br> Gestiamo e coordiniamo:<br><br> ATTIVITA’ E/O PIANI DI VERIFICA PERIODICA DI CANTIERE'

  return (
    <>
      <ConsultingTemplate seoKeywords={seoKeywords} productName={productName} description={description} />
    </>
  )
}
